var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 mt-4 pb-2"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.ownership2")) + " ")]), _c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.property.category.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("category")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.property.category.$model,
      expression: "$v.property.category.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.property.category, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "Sebidang Tanah"
    }
  }, [_vm._v(_vm._s(_vm.$t("piece-of-land")))]), _c('option', {
    attrs: {
      "value": "Sebuah Bangunan"
    }
  }, [_vm._v(_vm._s(_vm.$t("a-building")))]), _c('option', {
    attrs: {
      "value": "Hak Milik Strata"
    }
  }, [_vm._v(_vm._s(_vm.$t("strata-ownership")))])]), _vm.$v.property.category.$error && !_vm.$v.property.category.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("category")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.property.property_type.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("property-type")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.property.property_type.$model,
      expression: "$v.property.property_type.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.property.property_type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "Geran"
    }
  }, [_vm._v(_vm._s(_vm.$t("grant")) + " (Geran)")]), _c('option', {
    attrs: {
      "value": "Pajakan Negeri"
    }
  }, [_vm._v(_vm._s(_vm.$t("state-lease")) + " (PN)")]), _c('option', {
    attrs: {
      "value": "Geran Mukim"
    }
  }, [_vm._v(_vm._s(_vm.$t("subdistrict-grant")) + " (GM)")]), _c('option', {
    attrs: {
      "value": "Pajakan Mukim"
    }
  }, [_vm._v(_vm._s(_vm.$t("subdistrict-lease")) + " (PM)")]), _c('option', {
    attrs: {
      "value": "Hak Milik Sementara Daerah"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("district-temp-ownership")) + " (HSD) ")]), _c('option', {
    attrs: {
      "value": "Hak Milik Sementara Mukim"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("subdistrict-temp-ownership")) + " (HSM) ")]), _c('option', {
    attrs: {
      "value": "Hakmilik Induk Hakmilik Sementara Daerah HS(D)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("master-temp-district")) + " HS(D) ")]), _c('option', {
    attrs: {
      "value": "Hakmilik Sementara Mukim MCL - HMM"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("subdistrict-temp-ownership")) + " MCL - HMM ")]), _c('option', {
    attrs: {
      "value": "Geran Mukim MCL - GMM"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("subdistrict-grant")) + " MCL - GMM ")])]), _vm.$v.property.property_type.$error && !_vm.$v.property.property_type.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("property-type")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.property.property_no.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("property-no")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.property.property_no.$model,
      expression: "$v.property.property_no.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": ""
    },
    domProps: {
      "value": _vm.$v.property.property_no.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.property.property_no, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.property.property_no.$error && !_vm.$v.property.property_no.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("property-no")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.property.lot_number.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("lot-no")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.property.lot_number.$model,
      expression: "$v.property.lot_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": ""
    },
    domProps: {
      "value": _vm.$v.property.lot_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.property.lot_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.property.lot_number.$error && !_vm.$v.property.lot_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("lot-no")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.property.city_type.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("city")) + " / " + _vm._s(_vm.$t("town")) + " / " + _vm._s(_vm.$t("subdistrict")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.property.city_type.$model,
      expression: "$v.property.city_type.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.property.city_type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "Bandar"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('option', {
    attrs: {
      "value": "Pekan"
    }
  }, [_vm._v(_vm._s(_vm.$t("town")))]), _c('option', {
    attrs: {
      "value": "Mukim"
    }
  }, [_vm._v(_vm._s(_vm.$t("subdistrict")))])]), _vm.$v.property.city_type.$error && !_vm.$v.property.city_type.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("this-option")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.property.city.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("city")) + " / " + _vm._s(_vm.$t("town")) + " / " + _vm._s(_vm.$t("subdistrict")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.property.city.$model,
      expression: "$v.property.city.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "placeholder": _vm.$t('please-state')
    },
    domProps: {
      "value": _vm.$v.property.city.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.property.city, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.property.city.$error && !_vm.$v.property.city.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.city-others")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row d-flex justify-content-star"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.property.district.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("district")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.property.district.$model,
      expression: "$v.property.district.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": ""
    },
    domProps: {
      "value": _vm.$v.property.district.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.property.district, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.property.district.$error && !_vm.$v.property.district.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("district")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.property.state.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.property.state.$model,
      expression: "$v.property.state.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": ""
    },
    domProps: {
      "value": _vm.$v.property.state.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.property.state, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.property.state.$error && !_vm.$v.property.state.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("state")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.property.property_portion.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("part")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.property.property_portion.$model,
      expression: "$v.property.property_portion.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": ""
    },
    domProps: {
      "value": _vm.$v.property.property_portion.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.property.property_portion, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.property.property_portion.$error && !_vm.$v.property.property_portion.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.property.address,
      expression: "property.address"
    }],
    staticClass: "form-control",
    attrs: {
      "rows": "2"
    },
    domProps: {
      "value": _vm.property.address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.property, "address", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [[0, 1, 4].includes(_vm.amendment_status) ? _c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.registerImmoveableProperty
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.save-prop")) + " ")]) : _vm._e()], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }